import {
  getLoadErrorTags,
  getLoadedTags,
  getLoadingTags,
  getConfig,
  getAppId,
} from './modules/stateCache';
import { publishEvent, eventNames } from './modules/events';
import { onPageNavigation, onConsentPolicyChanged } from './integration/viewer';
import { getSiteTags } from './API/siteApi';



let initTagManager = () => {
  const wixEmbedsAPI = (window as any).wixEmbedsAPI;
  getSiteTags({
    baseUrl: wixEmbedsAPI.getExternalBaseUrl(),
    htmlsiteId: wixEmbedsAPI.getHtmlSiteId(),
    metasiteId: wixEmbedsAPI.getMetaSiteId(),
    language: wixEmbedsAPI.getLanguage(),
    wixSite: wixEmbedsAPI.isWixSite(),
  });
  (window as any).wixEmbedsAPI.registerToEvent(
    'pageNavigation',
    onPageNavigation,
  );
  document.addEventListener('consentPolicyChanged', onConsentPolicyChanged);
  registerTagManagerAPI();
};

registerToWixEmbedsAPI();

function registerTagManagerAPI() {
  const api = Object.freeze({
    getLoadedTags,
    getLoadingTags,
    getLoadErrorTags,
    getConfig,
    getAppId,
  });

  Object.defineProperty(window, 'wixTagManager', {
    value: api,
    writable: false,
    configurable: false,
    enumerable: true,
  });
  publishEvent(
    eventNames.TAG_MANAGER_LOADED,
    window as any,
    window.wixTagManager,
  );
}

function registerToWixEmbedsAPI() {
  if (
    (window as any).wixEmbedsAPI &&
    typeof (window as any).wixEmbedsAPI.registerToEvent === 'function'
  ) {
    initTagManager();
  } else {
    (window as any).addEventListener(
      'wixEmbedsAPIReady',
      () => {
        initTagManager();
        // Noop-ified to prevent duplicate callbacks
        initTagManager = function () {};
      },
      false,
    );
  }
}
